<template>
  <div id="EditCircle">
    <div class="top">
      <span @click="back()">取消</span>
      <span :class="{'active': sendActive || comContent || fileList.length}" @click="toSend">发表</span>
    </div>
    <div id="nowFell">
      <div class="pre" ref="pre" @input="change($event)" @click.stop="toFocus"  v-html="comContent" ></div>
      <div @click.stop="toFocus"  class="nowmethods" v-if="!(sendActive || comContent) ">这一刻的想法...</div>
      <!-- <van-field ref="textareaFocus" @focus.stop="focus()"  v-model="comContent" rows="1" autofocus :autosize= {maxHeight:80}  type="textarea" placeholder="这一刻的想法" /> -->
    </div>
    <div v-if="fileType == 0" style="padding: 0 .1rem">
      <van-uploader  v-model="fileList"  multiple :max-count="9"  />
    </div>

    <!-- 下面是视频 -->
    <div id="onlyVideo" v-if="fileType == 1">
      <video :src="videoSrc" controls="controls"   class="video_file "></video>
    </div>
    <div v-if="fileType == 1" class="van-uploader__upload">
      <i class="van-icon van-icon-photograph van-uploader__upload-icon"><!----></i>
      <input type="file" @change="changeVideo" id="imgFile" accept="video/*" class="van-uploader__input " />
    </div>

    <p class="tip"><van-icon name="info" /> 发表后需等待审核，审核通过后方可展示</p>

    <!-- <div id="allEmotion" v-if="smileShow">
      <div>
        <img v-if="srcPng" id="fsl" @click.stop="changeSimle" src="~@/assets/image/key.png" />
        <img v-else id="face" @click.stop="changeSimle" src="~@/assets/image/smileb.png" />
      </div>
    </div> -->
  </div>
</template>

<script>
// import { ImagePreview } from 'vant';
import $ from 'jquery'
// import qs from 'qs'
// import  '../assets/js/jquery.sinaemotion'
export default {
  name: 'EditCircle',
  data(){
    return {
      fileList:[],//图片列表
      smileShow:false,//表情
      srcPng:false,
      sendActive:'',
      videoSrc:'',
      userBaseInfo:'',
      videoFile:'',//文件
      fileType:'',//照片还是视频
      baseInfo:'',
      comContent:''
    }
  },
  mounted(){
     

    this.id = this.$route.query.id
    this.fileType = 0
    // this.userBaseInfo = localStorage.getItem("userBaseInfo")
    // this.z_id = this.$route.query.z_id || localStorage.getItem("z_id")
    this.baseInfo = localStorage.getItem('baseInfo')

    if(this.id){
      this.axios({
        method: 'GET',
        url: '/share/getAuditPass?shareId='+this.id,
      }).then((res) => {
        if(res.data.code == 0){
          this.comContent = res.data.data.title
          console.log(res.data.data)
          if(res.data.data.doc_friend.image1){
            this.fileList.push({url:res.data.data.doc_friend.image1})
          }
          if(res.data.data.doc_friend.image2){
            this.fileList.push({url:res.data.data.doc_friend.image2})
          }
          if(res.data.data.doc_friend.image3){
            this.fileList.push({url:res.data.data.doc_friend.image3})
          }
          if(res.data.data.doc_friend.image4){
            this.fileList.push({url:res.data.data.doc_friend.image4})
          }
          if(res.data.data.doc_friend.image5){
            this.fileList.push({url:res.data.data.doc_friend.image5})
          }
          if(res.data.data.doc_friend.image6){
            this.fileList.push({url:res.data.data.doc_friend.image6})
          }
          if(res.data.data.doc_friend.image7){
            this.fileList.push({url:res.data.data.doc_friend.image7})
          }
          if(res.data.data.doc_friend.image8){
            this.fileList.push({url:res.data.data.doc_friend.image8})
          }
          if(res.data.data.doc_friend.image9){
            this.fileList.push({url:res.data.data.doc_friend.image9})
          }
        }
      })
    }else{
      this.comContent = ''
    }
    this.$refs.pre.focus();
    var _this = this
    document.addEventListener('click', _this.changeType)
  },
  beforeDestroy(){
    var _this = this;
    document.removeEventListener('click',_this.changeType);
  },
  methods:{
    // 视频
    changeVideo() {
      // let that = this;
      var file = document.getElementById("imgFile").files[0];
      this.videoFile = file;
      console.log(file)

      var fileReader = new FileReader()
      fileReader .readAsDataURL(file)

      var that = this
      fileReader.onload = function (e) {
        that.videoSrc = e.currentTarget.result
      }


      // var ids = this.ids;
      // var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加 
      // //  到FormData里面，这样就可以直接把formData作为参数传递了
      // if (file) {
      //     formData.append("file", file); //接口需要传递的参数
      // }
      // let potss = formData;
      // let urls = "http:url地址";
      // axios
      //     .post(urls, potss)
      //     .then(function(res) {
      //         that.prames.videoUrl = res.data.info;
      //         that.videoshow = false;
      //     })
      //     .catch(function(err) {
      //         console.log(err);
      //     });
    },
    back() {
      this.$router.go(-1)
    },
    // 发表
    toSend(){
      var _this = this;
      if(this.sendActive || this.comContent || this.fileList.length){
        this.$toast.loading({ 
          message: '发布中',
          forbidClick: true,
        });
        console.log(this.fileList)
        let data = new FormData()
        for(var i in this.fileList){
          if(this.fileList[i].file){
            data.append('image'+(Number(i)+1),this.fileList[i].file)
          }else{
            data.append('image'+(Number(i)+1),this.fileList[i].url)
          }
        }

        var content = $(".pre").html();
        // var z_id = this.z_id;
        // var userid = this.userBaseInfo.userid
        // data.append('image',file)
        // data.append('z_id',z_id)
        // data.append('userid',userid)
        if(content){
          data.append('title',content)
        }
        // data.append("userId",localStorage.getItem("baseInfo").userId);
        var url;
        if(this.id){ //星球
          url = '/share/issueUserShare/' + this.id
        }else{
          url = '/share/addUserShare'
        }
        this.axios({
          method: 'POST',
          url: url,
          dataType: 'json',
          headers:{
            'Content-Type':'multipart/form-data'
          },
          data:data
        }).then((res) => {
          this.$toast.clear()
          if(res.data.code == 0){
            this.$toast.clear();
            this.$toast.success('发布成功');
            setTimeout(function(){
              _this.$router.push({
                path: '/Inspiration',
              })
            },2000)
          }else{
            this.$toast(res.data.message);
          }
          
        })
      }
    },
    // 监听输入内容
    change(e){
      // console.log(e)
      // console.log(e.target.innerHTML)
      // console.log(Boolean(e.target.innerHTML))

      if (e.target.innerHTML) {
        var str = e.target.innerHTML.replace(/<br>/ig, '').replace(/\s/g,'')
        this.sendActive = str
      } else {
        this.sendActive = false
      }

      if(!e.target.innerHTML){
        this.comContent = e.target.innerHTML
      }
    },
    toFocus(){
      this.$refs.pre.focus();
      this.focus()
    },
    changeSimle(){
      if($("#emotions").css('display') == 'block'){
        this.srcPng = true
        this.$refs.pre.focus();
        // this.$refs.textareaFocus.scrollIntoView();
        // setTimeout(function(){
        //   $("#allEmotion #emotions").css('display','none')
        // },100)
      }else{
        this.srcPng = false
      }
    },
    // 聚焦弹出表情
    focus(){
      // this.smileShow = true
      // setTimeout(function(){
      //   $('#face').SinaEmotion($('#allEmotion'));
      // },100)
      this.srcPng = false
      this.$refs.pre.focus();
      // this.$refs.textareaFocus.scrollIntoView();
      // setTimeout(function(){
      //   $("#allEmotion #emotions").css('display','none')
      // },150)
      
    },
     changeType(){
      this.smileShow = false
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.pre {
  caret-color:#3890FF;
  margin-top: .2rem;
  padding: 10px 0;
  overflow: auto;
  box-sizing: border-box;
  overflow: auto;
  color: #F6F7FD;
  font-size: 0.16rem;
  line-height: .2rem;
  min-height: 24px;
  max-height: 88px;
  height: 100%;
  z-index: 10;
  text-align: left;
  width: 100%;
  user-modify: read-write;
  user-modify: read-write-plaintext-only;
  -webkit-user-modify: read-write-plaintext-only;
  outline: none;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
#onlyVideo{
  text-align: left;
}
.video_file{
  width: 50%;
}
#nowFell{
  position: relative;
  padding-bottom: 0.25rem;
  padding-bottom: .25rem;
  padding-left: .1rem;
  padding-right: .1rem;
  .nowmethods{
    padding: 10px 0;
    position: absolute;
    top: 0;
    text-align: left;
    font-size: 0.16rem;
    color: #b1b1b1;
  }
}
#allEmotion{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  >div:first-child{
    padding: 0 0.1rem;
    height: 47px;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #dedede;
    img{
      width: 25px;
      margin-left: 4px;
    }
  }
}
.top{
  text-align: right;
  font-size: 0.16rem;
  .active{
    background: #3890FF !important;
  }
  >span:first-child {
    float: left;
    color: #F6F7FD;
    margin-top: .05rem;
  }
  >span:last-child{
    display: inline-block;
    width: 0.56rem;
    height: 0.28rem;
    line-height: 0.27rem;
    text-align: center;
    border-radius: 5px;
    background: #989AB1;
    margin-bottom: 5px;
    color: #F6F7FD;
    font-weight: bolder;
  }
}
.tip {
  color: #b1b1b1;
  padding: .08rem .1rem;
}
#EditCircle{
  padding: 0.15rem;
  background: #23252E;
}
</style>

